import React from "react"
import styled from "@emotion/styled"
import {
  AccordionTitle,
  AccordionContent,
  Container,
  Icon,
} from "semantic-ui-react"
import mq from "./mq"
import theme from "./theme"
import { isMobile } from "@utils/Helpers"

interface BaseTextProps extends React.HTMLAttributes<HTMLElement> {
  textAlign?: "left" | "center" | "right";
  color?: string;
  top?: number;
  bottom?: number;
  darkTheme?: boolean;
  underline?: boolean;
  width?: string;
  fontSize?: string;
  align?: "left" | "center" | "right";
  [key: string]: any; // Allow additional props
}

/* eslint-disable jsx-a11y/heading-has-content, jsx-a11y/label-has-associated-control,jsx-a11y/anchor-has-content */
export const H1 = styled(({ textAlign, color, ...others }: BaseTextProps) => (
  <h1 {...others}></h1>
))`
  ${mq({
    fontSize: ["30px", "30px", "44px"],
    lineHeight: ["44px", "44px", "62px"],
    letterSpacing: ["-0.6px", "-0.6px", "-0.88px"],
  })}
  font-weight: bold;
  font-family: itc-avant-garde-gothic-pro, sans-serif !important;
  text-align: ${props => props.textAlign};
  color: ${props => props.color};
`
export const H2 = styled(({ textAlign, color, ...others }: BaseTextProps) => (
  <h2 {...others}></h2>
))`
  ${mq({
    fontSize: ["26px", "26px", "36px"],
    lineHeight: ["36px", "36px", "50px"],
    letterSpacing: ["-0.52px", "-0.52px", "-0.72px"],
  })}
  font-weight: bold;
  font-family: itc-avant-garde-gothic-pro, sans-serif !important;
  text-align: ${props => props.textAlign};
  color: ${props => props.color};
`

export const H3 = styled(({ textAlign, color, ...others }: BaseTextProps) => (
  <h3 {...others}></h3>
))`
  ${mq({
    fontSize: ["24px", "24px", "30px"],
    lineHeight: ["34px", "34px", "42px"],
    letterSpacing: ["0px", "0px", "-0.96px"],
  })}
  font-weight: bold;
  font-family: itc-avant-garde-gothic-pro, sans-serif !important;
  text-align: ${props => props.textAlign};
  color: ${props => props.color};
`
export const H4 = styled(({ textAlign, color, ...others }: BaseTextProps) => (
  <h4 {...others}></h4>
))`
  ${mq({
    fontSize: ["20px", "22px", "24px"],
    lineHeight: ["28px", "28px", "30px"],
    letterSpacing: ["-0.4px", "-0.4px", "-0.48px"],
  })}
  font-weight: bold;
  letter-spacing: 0;
  font-family: itc-avant-garde-gothic-pro, sans-serif !important;
  text-align: ${props => props.textAlign};
  color: ${props => props.color};
`
export const H5 = styled(({ textAlign, color, ...others }: BaseTextProps) => (
  <h5 {...others}></h5>
))`
  ${mq({
    fontSize: ["20px", "20px", "22px"],
    lineHeight: ["28px", "28px", "30px"],
    letterSpacing: ["-0.44px", "-0.44px", "-0.44px"],
  })}
  font-weight: bold;
  text-align: ${props => props.textAlign};
  font-family: itc-avant-garde-gothic-pro, sans-serif !important;
  color: ${props => props.color};
`
export const H6 = styled(({ textAlign, color, ...others }: BaseTextProps) => (
  <h6 {...others} />
))`
  ${mq({
    fontSize: ["14px", "14px", "16px"],
    lineHeight: ["24px", "24px", "22px"],
  })}
  font-weight: bold;
  text-align: ${props => props.textAlign};
  font-family: itc-avant-garde-gothic-pro, sans-serif !important;
  margin: 0 !important;
  color: ${props => props.color};
`

export const Paragraph1 = styled(({ color, textAlign, ...others }: BaseTextProps) => (
  <p {...others} />
))`
  ${mq({
    fontSize: ["14px", "14px", "16px"],
    lineHeight: ["24px", "24px", "24px"],
  })}
  font-weight: light;
  letter-spacing: -0.32px;
  color: ${props => props.color};
  text-align: ${props => props.textAlign};
`

export const Disclaimer = styled(({ color, textAlign, ...others }: BaseTextProps) => (
  <div {...others} />
))`
  ${mq({
    fontSize: ["12px", "12px", "12px"],
    lineHeight: ["18px", "18px", "18px"],
  })}
  color: ${props => props.color};
  text-align: ${props => props.textAlign};
`

export const Paragraph2 = styled(({ color, ...others }: BaseTextProps) => <p {...others} />)`
  ${mq({
    fontSize: ["14px"],
    lineHeight: ["24px"],
  })}
  font-weight: light;
  letter-spacing: -0.32px;
  color: ${props => props.color};
`

export const Caption = styled(({ color, top, bottom, ...others }: BaseTextProps) => (
  <div {...others} />
))`
  ${mq({
    fontSize: ["12px", "14px", "14px"],
    lineHeight: ["18px", "20px", "20px"],
  })}
  font-weight: light;
  display: flex;
  color: ${props => props.color};
  padding-top: ${props => (props.top === undefined ? "0px" : props.top + "px")};
  padding-bottom: ${props =>
    props.bottom === undefined ? "0px" : props.bottom + "px"};
`

export const Legal = styled(({ color, top, bottom, ...others }: BaseTextProps) => (
  <small {...others} />
))`
    ${mq({
      fontSize: ["10px", "12px", "12px"],
      lineHeight: ["16px", "18px", "18px"],
    })}
    padding-top: ${props =>
      props.top === undefined ? "0px" : props.top + "px"};
    padding-bottom: ${props =>
      props.bottom === undefined ? "0px" : props.bottom + "px"};
    color: ${props => props.color};
`

export const LegalDiv = styled(({ color, top, bottom, darkTheme, ...others }: BaseTextProps) => (
  <div {...others} />
))`
    ${mq({
      fontSize: ["12px", "12px", "12px"],
      lineHeight: ["16px", "18px", "18px"],
      //color: [p => p.darkTheme ? theme.brand.colors.white : theme.brand.colors.lightToGrey, p =>p.darkTheme ? theme.brand.colors.white : theme.brand.colors.darkGrey, pdarkTheme ? theme.brand.colors.white : theme.brand.colors.darkGrey]
    })}
    color: ${p => p.darkTheme ? 'white !important' : '#707070 !important'};
    padding-top: ${props =>
      props.top === undefined ? "0px" : props.top + "px"};
    padding-bottom: ${props =>
      props.bottom === undefined ? "0px" : props.bottom + "px"};
`

export const CopyRights = styled(({ color, darkTheme, ...others }: BaseTextProps) => (
  <small {...others} />
))`
  ${mq({
    fontSize: ["13px !important", "13px !important", "13px !important"],
    lineHeight: ["19px !important", "19px !important", "19px !important"],
  })}
  font-weight: normal !important;
  color: ${props => props.darkTheme ? '#eee' : props.color};
  text-transform: none !important;
`

export const KpAccordionTitle = styled(({ color, ...others }: BaseTextProps) => (
  <AccordionTitle {...others} />
))`
  &&&& {
    ${mq({
      fontSize: ["18px", "18px", "18px"],
      lineHeight: ["26px", "26px", "26px"],
    })}
    letter-spacing: -0.36px;
    color: ${props => props.color};
  }
`

export const KpAccordionContent = styled(AccordionContent)`
  &&& {
    ${mq({
      fontSize: ["16px", "16px", "16px"],
      lineHeight: ["24px", "24px", "24px"],
    })}
    letter-spacing: -0.36px;
  }
`

export const ReviewCardTitle = styled.span`
  ${mq({
    fontSize: ["14px", "16px", "20px"],
    lineHeight: ["16px", "18px", "28px"],
  })}
  text-align: left;
  font-weight: bold;
`

export const CustomContainer = styled(({ width, ...others }: BaseTextProps) => (
  <Container {...others} />
))`
  width: ${props => props.width} !important;
`

export const ModalContainer = styled.div`
  text-align: center;
  width: 250px;
  padding-top: 300px;
  margin: auto;
`
export const ModalHeading = styled.div`
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  letter-spacing: -0.48px;
`
export const ModalTitle = styled.div`
  ${mq({
    fontSize: ["18px", "18px", "18px"],
    lineHeight: ["26px", "26px", "26px"],
  })}
  font-weight: bold;
  letter-spacing: -0.36px;
  color: ${props => props.color};
`

export const ModalSubtitle = styled.div`
  font-size: 12px;
  line-height: 22px;
  font-weight: light;
  padding-bottom: 15px;
  color: ${theme.brand.colors.darkGrey};
`

//
export const LinkText = styled(({ underline, ...others }: BaseTextProps) => <a {...others} />)`
  color: ${theme.brand.primary};
  text-decoration: ${props => (props.underline ? "underline" : "none")};
  cursor: pointer;
  :hover {
    color: ${theme.brand.primary};
  }
`

export const SummaryGroupLabel = styled(({ color, align, fontSize,...others }: BaseTextProps) => (
  <label {...others} />
))`
    ${mq({
      lineHeight: ["22px"],
    })}
    font-size: ${props => (props.fontSize ? props.fontSize : "16px")};
    color: ${props => (props.color ? props.color : "black")};
    float: ${props => (props.align ? props.align : "left")};
    font-weight: bold;
`

export const SummaryItemLabel = styled.label`
  ${mq({
    fontSize: ["15px"],
    lineHeight: ["21px"],
  })}
  font-weight: bold;
`

export const SummaryItem = styled.span`
    ${mq({
      fontSize: ["15px"],
      lineHeight: ["21px"],
    })}
    color: ${ isMobile() ? theme.brand.colors.black : theme.brand.colors.darkGrey};
    float: right;
`
export const PopupContentText = styled.div`
  color: ${theme.brand.colors.darkGrey};
  font-size: 13px;
  line-height: 18px;
`
export const DpsContainer = styled.iframe`
  border: none;
`
export const ErrorContainer = styled.div`
  border-top: 2px solid ${theme.brand.colors.error};
  border-bottom: 2px solid ${theme.brand.colors.error};
  color: ${theme.brand.colors.error};
  padding: 20px;
`
export const MessageContainer = styled.div`
  border-top: 2px solid ${theme.brand.colors.green};
  border-bottom: 2px solid ${theme.brand.colors.green};
  color: ${theme.brand.colors.green};
  padding: 20px;
`
export const FullContainer = styled(Container)`
  margin-left: 0 !important;
  margin-right: 0 !important;
`
export const ModalCloseIcon = styled(Icon)`
  float: right;
  top: 1rem !important;
  right: 2rem !important;
  color: black !important;
`
